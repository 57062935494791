import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    saveSwap: 0,
    isAuth: false,
    isDarkTheme: true,
    event: null,
    isVerifyPopup: false,
    profile: {
        userName: '',
        userEmail: '',
        isEmailConfirmed: false,
        isPremium: false,
        userPhoto: null,
        id: null,
    },
    totalBalance: {
        coinBalance: 0,
        coinName: '',
        type: '',
        usdBalance: 0,
        chartFirst: 0,
        chartSecond: 0,
        chartTotal: 0,
        chartOthersValue: 100,
    },
};

export const accountSlice = createSlice({
    name: 'accountPage',
    initialState,
    reducers: {
        setSaveSwap(state, {payload}) {
            state.saveSwap = payload;
        },
        setTheme(state, {payload}) {
            state.isDarkTheme = payload;
        },
        setAuth(state, {payload}) {
            state.isAuth = payload;
        },
        setEvent(state, {payload}) {
            state.event = payload;
        },
        setIsVerifyPopup(state, {payload}) {
            state.isVerifyPopup = payload;
        },
        setProfile(state, {payload}) {
            state.profile = {
                userName: payload.username,
                userEmail: payload.email,
                isEmailConfirmed: payload.email_confirmed,
                isPremium: payload.premium,
                userPhoto: payload.profile_photo,
                id: payload.id,
                twoFactor: payload.twoFA,
                kycVerified: payload.kycVerified,
                lastActivity: payload.lastActivity
            }
        },
        change2FA(state, {payload}) {
            console.log(payload)
            state.profile = {
                userName: state.username,
                userEmail: state.email,
                isEmailConfirmed: state.email_confirmed,
                isPremium: state.premium,
                userPhoto: state.profile_photo,
                id: state.id,
                twoFactor: payload,
                kycVerified: state.kycVerified,
                lastActivity: state.lastActivity
            }
        },
        updateProfileName(state, {payload}) {
            state.profile = {
                userName: payload,
                userEmail: state.profile.email,
                isEmailConfirmed: state.profile.email_confirmed,
                isPremium: state.profile.premium,
                userPhoto: state.profile.profile_photo,
                id: state.profile.id,
                twoFactor: state.profile.twoFA,
                kycVerified: payload.kycVerified,
                lastActivity: payload.lastActivity
            }
        },
        setBalance(state, {payload}) {
            state.totalBalance = {
                coinBalance: payload.coin_balance,
                coinName: payload.coin_name,
                type: payload.type,
                usdBalance: payload.usd_balance,
                chartFirstName: payload.allocations.firstName,
                chartFirstValue: Number(payload.allocations.firstValue),
                chartSecondName: payload.allocations.secondName,
                chartSecondValue: Number(payload.allocations.secondValue),
                chartOthersValue: payload.allocations.firstValue == 0 || payload.allocations.firstValue == 0 ? 100 : 100 - (Number(payload.allocations.secondValue) + Number(payload.allocations.firstValue)),
                chartSecond: 0,
                chartTotal: 0,
            };
        },
    }
})

export default accountSlice.reducer;

export const {
    setSaveSwap,
    setTheme,
    setAuth,
    setProfile,
    setBalance,
    setIsVerifyPopup,
    setEvent,
    updateProfileName,
    change2FA,
} = accountSlice.actions;