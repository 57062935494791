import React from "react";
import styles from './Filters.module.scss';
import {useAppSelector} from "../../../../../hooks/redux";
import {getIsDarkTheme} from "../../../../../store/selectors";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const FilterSelect = ({selectList, value, setValue, text, isToken = false}) => {
    const isDark = useAppSelector(getIsDarkTheme);

    const handleChange = (item) => {
        if (value.value !== item.value) {
            setValue(item);
        }
    }

    return (
        <Box>
            <FormControl fullWidth>
                <Select
                    IconComponent={() => <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#9497A9"/>
                    </svg>
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value?.fullname}

                    sx={{
                        border: isDark ? '1px solid #201D2B' : '1px solid #EDEDF0',
                        backgroundColor: isDark ? '#201D2B' : '#EDEDF0',
                        height: '38px',
                        borderRadius: '8px',
                        color: '#fff',
                        '.MuiSvgIcon-root ': isDark ? {
                            fill: "white !important",
                        } : null,
                    }}
                >
                    {selectList && selectList.length > 0 && (
                        selectList.map((item, index) => (
                            <MenuItem key={index} value={item.fullname} onClick={() => handleChange(item)}>
                                {isToken
                                    ? <p className={styles.option}>
                                        <span>{item.fullname}</span>
                                        {item.fullname !== text.all && (
                                            <span className={styles.option__token}>({item.label})</span>
                                        )}
                                    </p>
                                    : <p className={styles.option}>
                                        <span>{item.fullname}</span>
                                    </p>
                                }
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </Box>
    )
}

export default FilterSelect;