import React, {useState} from "react";
import userNoPhoto from "../../../../assets/icons/UserNoPhoto.svg";
import userNoPhotoDark from "../../../../assets/icons/UserNoPhotoDark.svg";
import styles from './SettAccount.module.scss';
import {Skills} from "../../../Common/Skills/Skills";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getIsDarkTheme, getIsVerifyPopup, getProfileInfo, getText} from "../../../../store/selectors";
import {generateCode, getUserInfo, sendNewEmail, setNewName} from "../../../../api/api";
import CodeBox from "./CodeBox";
import {setEvent, setIsVerifyPopup, setProfile, updateProfileName} from "../../../../store/account-slice";
import VerifyPopup from "../../../Common/VerifyPopup/VerifyPopup";
import {Preloader} from "../../../Common/Preloader/Preloader";

const SettAccount = ({text}) => {
    const dispatch = useAppDispatch();
    const {notification} = useAppSelector(getText);
    const isDark = useAppSelector(getIsDarkTheme);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(0);
    const [showCode, setShowCode] = useState(false);
    const profile = useAppSelector(getProfileInfo);
    const is2FAVerify = useAppSelector(getIsVerifyPopup);
    const [isLoading, setIsLoading] = useState(false);

    const refreshProfileData = () => {
        getUserInfo().then((result) => {
            if (result && result.username) {
                dispatch(setProfile(result));
                dispatch({text: notification.mailChanged, IsCompleted: true});
                setEmail('');
                setIsLoading(false);
                setCode(0)
                setShowCode(false);
            } else {
                setIsLoading(false)
            }
        })
    }

    const onSave = () => {
        if (name && name.length > 5) {
            setNewName(name).then((response) => {
                if (response && response.status && response.status === 'success') {
                    dispatch(updateProfileName(name))
                    dispatch(setEvent({text: notification.usernameChanged, IsCompleted: true}));
                    setName('');
                }
                else {
                    dispatch(setEvent({text: notification.invalidUsername, IsCompleted: false}));
                }
            })
        }
        if (email && email.length > 5 && !showCode) {
            const result = String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            if (result) {
                if (profile && profile.twoFactor) {
                    dispatch(setIsVerifyPopup(true));
                } else {
                    generateCode().then(response => {
                        if (response && response.status === 'success') {
                            setShowCode(true);
                        }
                    });
                }
            }
        }
        if (email && email.length > 5 && showCode && code.toString().length === 4) {
            setIsLoading(true);
            sendNewEmail(code, email).then((result) => {
                if (result && result.status === 'success') {
                    refreshProfileData();
                } else {
                    dispatch(setEvent({text: notification.errorMail, IsCompleted: false}));
                    setIsLoading(false);
                }
            });
        }
    }

    const onSend = (code) => {
        setIsLoading(true);
        generateCode(code).then(response => {
            if (response && response.status === 'success') {
                setShowCode(true);
                setIsLoading(false);
            } else {
                dispatch(setEvent({text: notification.invalidCode, IsCompleted: false}))
                setIsLoading(false);
            }

        });
    }


    return (
        <div className={styles.settAccount}>
            <div className={styles.authBox}>
                <div className={styles.usePhoto}
                     style={isDark ? {border: '1px solid #fff'} : {border: '1px solid #C9CEDE'}}>
                    <img src={isDark ? userNoPhoto : userNoPhotoDark} alt=""/>
                </div>
                <span>{profile.userName}</span>
            </div>
            <div className={`${styles.settAccount__skills} skill-set`}>
                <Skills profile={profile} />
            </div>
            <form className={styles.form}>
                <input className={'customField'} placeholder={text.newNamePlaceholder} value={name}
                       onChange={(e) => setName(e.target.value)}
                       type="text"/>
                <input className={'customField'} placeholder={text.newEmailPlaceholder} value={email}
                       onChange={(e) => setEmail(e.target.value)} type="email"/>
                {showCode && (
                    <CodeBox code={code} setCode={setCode} text={text} />
                )}
            </form>
            <div className={styles.action}>
                <button type='submit' className={'button'} onClick={() => onSave()} disabled={name.length < 5 && email.length < 5}>
                    <span>{showCode ? text.confirm : text.save}</span>
                </button>
            </div>
            {is2FAVerify && (
                <VerifyPopup onSend={onSend} />
            )}
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default SettAccount;