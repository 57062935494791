import React from "react";
import styles from './TokenChanger.module.scss';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme, getPictureSrc} from "../../../store/selectors";

const TokenChanger = ({tokenList, token, setToken}) => {
    const isDark = useAppSelector(getIsDarkTheme);
    const pictureSrc = getPictureSrc;

    const handleChange = (value) => {
        setToken(value);
    };

    return (
        <div className={`${styles.tokenChanger} tokenChanger`}>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={token.fullname}
                        sx={{
                            borderRadius: '11px',
                            borderBlock: '#FFFFFF80',
                            color: '#fff',
                            '.MuiSvgIcon-root ': isDark ? {
                                fill: "white !important",
                            } : null,
                        }}
                    >
                        {tokenList && tokenList.length > 0 && (
                            tokenList.map((item, index) => (
                                <MenuItem key={index} value={item.fullname} onClick={() => handleChange(item)}>
                                    <p className={styles.option}>
                                        <img src={`${pictureSrc}${item.iconPath}`} alt=""/>
                                        <span>{item.fullname}</span><span className={styles.option__token}>({item.label})</span>
                                    </p>
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            </Box>
            {window.innerWidth > 1023 && (
                <div className={styles.tokenChangerButtons}>
                    {tokenList && tokenList.length > 0 && (
                        tokenList.map((item, index) => (
                            <div key={index} className={`${styles.tokenChangerButtons__toggle} toggleRadio`}>
                                <span>{item.label}</span>
                                <input type="radio" name='tokenChanger' checked={token.fullname === item.fullname} onChange={() => setToken(item)} />
                                <p>{item.label}</p>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    )
}

export default TokenChanger