import React from "react";
import styles from './Spot.module.scss';

const SpotChangeContent = ({isLeftContent, setIsLeftContent, isDark, text}) => {
    return (
        <div className={isDark ? `${styles.contentChanger}` : `${styles.contentChangerLight}`}>
            <div className={isDark ? `${styles.contentChanger__changer}` : `${styles.contentChangerLight__changer}`}>
                <input type="radio" name='scheduleChanger'
                       checked={isLeftContent === 'schedule'} onChange={() => {
                    setIsLeftContent('schedule');
                }} />
                <p style={isDark ? {backgroundColor: '#201D2B'} : {backgroundColor: '#EDEDF0'}}>{text.chart}</p>
            </div>
            <div className={isDark ? `${styles.contentChanger__changer}` : `${styles.contentChangerLight__changer}`}>
                <input type="radio" name='scheduleChanger'
                       checked={isLeftContent === 'spot'} onChange={() => {
                    setIsLeftContent('spot');
                }} />
                <p style={isDark ? {backgroundColor: '#201D2B'} : {backgroundColor: '#EDEDF0'}}>{text.spot}</p>
            </div>
        </div>
    )
}

export default SpotChangeContent;