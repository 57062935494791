import React from "react";
import styles from './HistoryListMob.module.scss';
import HistoryItemMob from "./HistoryItemMob";


const HistoryListMob = ({list, text, totalPage, page, setPage, onFilterChange}) => {

    return (
        <div className={styles.historyListMob}>
            {list && list.length > 0 && (
                list.map((item, index) => (
                    <HistoryItemMob key={index} item={item} text={text} totalPage={totalPage} page={page}
                                    setPage={setPage}/>
                ))
            )}
            {!(totalPage === 1 || totalPage === page) && (
                <div className={styles.showMore}>
                    <button className={styles.showMore__button} onClick={() => {
                        onFilterChange(page + 1)
                        setPage(page + 1);
                    }}>
                        <span>Show more</span>
                    </button>
                </div>
            )}
        </div>
    )
}

export default HistoryListMob;