import React, {useEffect, useState} from "react";
import styles from './Transfer.module.scss';
import OperationsRightBox from "../../../Common/OperationsRightBox/OperationsRightBox";
import TransferLeftBox from "./TransferLeftBox/TransferLeftBox";
import MobileChooser from "../../../Common/MobileChooser/MobileChooser";
import {getCoinSettings, getQuestions} from "../../../../api/api";
import {useAppSelector} from "../../../../hooks/redux";
import {getText} from "../../../../store/selectors";

const Transfer = () => {
    const {rightBox} = useAppSelector(getText);
    const [isLeft, setIsLeft] = useState(true)
    const [questionsList, setQuestionsList] = useState([]);
    const [token, setToken] = useState(null);
    const [settings, setSettings] = useState(null);

    const updateToken = (value) => {
        setToken(value);
    }

    useEffect(() => {
        if (questionsList.length === 0)
            getQuestions('transfer').then((res) => {
                if (res && res.length > 0) {
                    setQuestionsList(res);
                }
            })
    }, []);

    useEffect(() => {
        if (token) {
            getCoinSettings(token.label, 'STAKING').then((result) => {
                if (result) {
                    setSettings(result);
                }
            })

        }
    }, [token]);

    return (
        <div className={styles.wrapper}>
            {window.innerWidth > 743 && window.innerWidth < 1024 && (
                <MobileChooser isLeft={isLeft} setIsLeft={setIsLeft} leftText={'Workspace'}
                               rightText={'Questions and answers'}/>
            )}
            <div className={styles.transfer}>
                {window.innerWidth < 1024 && isLeft || window.innerWidth > 1023 ?
                    <div className={`${styles.transfer__leftBox} box`}>
                        <TransferLeftBox token={token} updateToken={updateToken} settings={settings} />
                    </div> : null
                }
                {window.innerWidth < 1024 && !isLeft || window.innerWidth > 1023 ?
                    <div className={`${styles.transfer__rightBox} box`}>
                        {token && settings && (
                            <OperationsRightBox questionsList={questionsList} text={rightBox} token={{
                                name: token.fullName,
                                label: token.label,
                                minimum: settings?.settings?.minTransferAmount,
                                operation: rightBox.minimumTransfer,
                            }}/>
                        )}
                    </div> : null
                }
            </div>
        </div>
    )
}

export default Transfer;