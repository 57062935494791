import React, { useEffect, useRef, memo } from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {getIsDarkTheme, getLanguage} from "../../../../store/selectors";

const TradingViewWidget = ({pair, isDark}) => {
    const container = useRef();
    const language = useAppSelector(getLanguage);

    let script;

    useEffect(
        () => {
            script = null;
            const settings = `{
                "autosize": true,
                "symbol": "HUOBI:${pair.simpleFormatted.toUpperCase()}",
                "interval": "1H",
                "timezone": "Etc/UTC",
                "theme": "${isDark ? 'dark' : 'light'}",
                "style": "1",
                "locale": "${language === 'russian' ? `ru` : `en`}",
                "allow_symbol_change": false,
                "calendar": false,
                "hide_side_toolbar": ${(window.innerWidth < 743)},
                "backgroundColor": "${isDark ? '#16121F' : '#fff'}",
                "support_host": "https://www.tradingview.com"
            }`;
            script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = settings;
            container.current.appendChild(script);
        },
        []
    );

    return (
        <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
        </div>
    );
}

export default memo(TradingViewWidget);