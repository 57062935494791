import React, {useState} from "react";
import styles from './LandingHeader.module.scss';
import LandingNavigation from "./LandingNavigation";
import logo from '../../../assets/images/logoAfs.svg'
import burger from '../../../assets/icons/burgerIcon.svg'
import close from '../../../assets/icons/cross.svg'
import {NavLink} from "react-router-dom";
import LendingMenu from "./LendingMenu";
import {useAppSelector} from "../../../hooks/redux";
import {getText} from "../../../store/selectors";

const LandingHeader = () => {
    const {header} = useAppSelector(getText);
    const navigation = [
        {name: header.trading, url: '/trading'},
        {name: header.p2p, url: '/'},
        {name: header.staking, url: '/'},
        {name: header.wallet, url: '/'},
        {name: header.swap, url: '/'},
    ];
    const [isShow, setIsShow] = useState(false);

    return (
        <div className={styles.header}>
            <NavLink to={'/'} className={styles.header__logo}>
                <img src={logo} alt=""/>
            </NavLink>

            {window.innerWidth > 1024 && (
                <LandingNavigation navigation={navigation}/>
            )}

            <div className={styles.header__authBox}>
                <NavLink to={'/login/'} className={styles.login}>{header.login}</NavLink>
                <NavLink to={'/register/'} className={styles.signUp}>
                    <span>{header.signUp}</span>
                </NavLink>
                {window.innerWidth < 1024 && (
                    <button onClick={() => setIsShow(!isShow)}>
                        <img src={isShow ? close : burger} alt=""/>
                    </button>
                )}
            </div>
            {window.innerWidth < 1024 && (
                <LendingMenu isShow={isShow} className={styles.menu} navigation={navigation}/>
            )}
        </div>

    )
}

export default LandingHeader;