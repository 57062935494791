import React from "react";
import styles from './NotificationContent.module.scss';
import NotificationItem from "./NotificationItem";


const NotificationContent = ({content}) => {
    return (
        <div className={styles.notificationContent}>
            {content && content.length > 0 && (
                content.map((item, index) => (
                    <NotificationItem key={index} item={item} />
                ))
            )}
        </div>
    )
}

export default NotificationContent;