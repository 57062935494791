import React, {useEffect, useState} from "react";
import styles from './SpotOrderForm.module.scss';
import SpotOrderFormBalances from "./SpotOrderFormBalances";
import CreditBox from "./CreditBox/CreditBox";
import SpotOperationsChangers from "./SpotOperationsChangers/SpotOperationsChangers";
import PriceInputBox from "../PriceInputBox/PriceInputBox";
import QuantityTotalBox from "./QuantityTotalBox/QuantityTotalBox";
import QuantityRange from "./QuantityRange/QuantityRange";
import {createMarginOrder, createOrder, getPairPrice, getUserBalances} from "../../../../../api/api";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {updateOrderList} from "../../../../../store/main-slice";
import {setEvent} from "../../../../../store/account-slice";
import {getText} from "../../../../../store/selectors";

const SpotOrderForm = ({pair, text, operation, setOperation, setPopupType, popupType, balance, setBalance,
                           secondBalance, setSecondBalance, trading, setTrading, isAuth}) => {
    const dispatch = useAppDispatch();
    const {notification} = useAppSelector(getText);
    const [priceChoose, setPriceChoose] = useState(text.limit);
    const [process, setProcess] = useState(0);
    const [coursePrice, setCoursePrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [total, setTotal] = useState('');
    const [isError, setIsError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [minimumError, setMinimumError] = useState(null);

    const refreshBalances = () => {
        getUserBalances(pair.base).then((response) => {
            if (response) {
                setBalance(response);
                setIsLoading(false);
            }
        })
        getUserBalances(pair.quote).then((response) => {
            if (response) {
                setSecondBalance(response);
                setIsLoading(false);
            }
        })
    }

    useEffect(() => {
        if (pair && isAuth) {
            getPairPrice(pair.value).then((result) => {
                if (result && result.price) {
                    setCoursePrice(Number(result.price).toFixed(2));
                    setQuantity('');
                    setTotal('');
                    setProcess(0);
                }
            })
            refreshBalances();
        }
    }, [pair])

    const onTransactionResponse = (response) => {
        dispatch(updateOrderList(response))
        dispatch(setEvent({text: notification.orderCreated, IsCompleted: true}));
        refreshBalances();
    }

    const send = (value) => {
        setIsLoading(true);
        if (pair?.value && coursePrice !== '') {
            if (trading === text.spotTrading) {
                createOrder(pair.value, priceChoose === text.limit ? 'limit' : 'market', value, quantity, coursePrice).then((response) => {
                    if (response && response.value) {
                        onTransactionResponse(response)
                    } else {
                        setIsLoading(false);
                        dispatch(setEvent({text: notification.orderFailed, IsCompleted: false}));
                    }
                });
            } else {
                createMarginOrder(pair.value, priceChoose === text.limit ? 'limit' : 'market', value, quantity, coursePrice).then((response) => {
                    if (response && response.value) {
                        onTransactionResponse(response)
                    } else {
                        setIsLoading(false);
                        dispatch(setEvent({text: notification.orderFailed, IsCompleted: false}));
                    }
                });
            }
        } else {
            setIsLoading(false);
        }
    }

    const onSubmit = (value) => {
        if (operation === 'Sell' && priceChoose === text.limit && quantity < pair.settings?.minLimitAmount) {
            setErrorText(`minimum limit amount ${pair.settings.minLimitAmount}`)
        } else if (operation === 'Sell' && priceChoose === text.limit && quantity > pair.settings?.maxLimitAmount) {
            setErrorText(`maximum limit amount ${pair.settings.minLimitAmount}`)
        } else if (operation === 'Sell' && priceChoose === text.market && quantity < pair.settings?.minMarketAmount) {
            setErrorText(`minimum market amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Sell' && priceChoose === text.market && quantity > pair.settings?.maxMarketAmount) {
            setErrorText(`maximum market amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Buy' && priceChoose === text.limit && total < pair.settings?.minLimitAmount) {
            setErrorText(`minimum limit amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Buy' && priceChoose === text.limit && total > pair.settings?.maxLimitAmount) {
            setErrorText(`maximum limit amount ${pair.settings.minMarketAmount}`)
        } else {
            setErrorText(null);
            send(value)
        }
    }


    return (
        <div className={`${styles.spotOrderForm} spotBg spot spotOrderForm`}>
            {balance && secondBalance && (
                <SpotOrderFormBalances coinBalance={balance} usdtBalance={secondBalance} trading={trading}
                                       operation={operation} coin={balance.coin} isError={isError} text={text}
                                       setPopupType={setPopupType} popupType={popupType} pair={pair}
                                       setBalance={setBalance} setSecondBalance={setSecondBalance}/>
            )}
            <CreditBox setQuantity={setQuantity} setTotal={setTotal} setProcess={setProcess} trading={trading}
                       setTrading={setTrading} text={text} isAuth={isAuth}/>
            <SpotOperationsChangers operation={operation} setOperation={setOperation} priceChoose={priceChoose}
                                    setPriceChoose={setPriceChoose} setQuantity={setQuantity} setTotal={setTotal}
                                    setProcess={setProcess} text={text} isAuth={isAuth} />
            {priceChoose === text.limit && (
                <PriceInputBox priceChoose={priceChoose}
                               coursePrice={coursePrice} quantity={quantity} isAuth={isAuth}
                               setCoursePrice={setCoursePrice} operation={operation}
                               setTotal={setTotal} text={text} settings={pair.settings}/>
            )}
            {balance && (
                <QuantityTotalBox quantity={quantity} priceChoose={priceChoose} setQuantity={setQuantity} total={total}
                                  setTotal={setTotal} operation={operation} coursePrice={coursePrice}
                                  quantityToken={operation === 'Buy' ? pair.quote : pair.base}
                                  totalToken={operation === 'Buy' ? pair.base : pair.quote} setProcess={setProcess}
                                  balance={operation === 'Buy' ? secondBalance?.balance : balance?.balance}
                                  isError={isError} setIsDisabled={setIsDisabled} minimumError={minimumError}
                                  setIsError={setIsError} text={text} settings={pair.settings} setMinimumError={setMinimumError}
                />
            )}
            {errorText && (
                <span className={styles.errorText}>{errorText}</span>
            )}
            {balance && balance?.balance > 0 && coursePrice && secondBalance?.balance && (
                <QuantityRange process={process} setProcess={setProcess} setQuantity={setQuantity}
                               coursePrice={coursePrice} minimumError={minimumError} setMinimumError={setMinimumError}
                               balance={operation === 'Buy' ? secondBalance?.balance : balance?.balance}
                               operation={operation} setTotal={setTotal} settings={pair.settings} setIsDisabled={setIsDisabled}
                />
            )}
            <div className={styles.action}>
                {isAuth ?
                    <>
                        {operation === 'Buy' ? (
                            <button className={styles.action__buy} onClick={() => onSubmit('buy')}
                                    disabled={quantity === '' || Number(quantity) === 0 || isError || isDisabled}>
                                <span>{text.buy}</span>
                            </button>
                        ) : (
                            <button className={styles.action__sell} onClick={() => onSubmit('sell')}
                                    disabled={quantity === '' || Number(quantity) === 0 || isError || isDisabled}>
                                <span>{text.sell}</span>
                            </button>
                        )}
                    </> :
                    <button className={`${styles.signBtn} blueBtn`}>
                        <span>{text.singToTrade}</span>
                    </button>
                }

            </div>
            {isLoading && (
                <div className='loadingLocal'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default SpotOrderForm;