import React from "react";
import styles from './NotificationTabs.module.scss';


const NotificationTabs = ({list, activeTab, setActiveTab}) => {
    return (
        <div className={`${styles.notificationTabs} box`}>
            {list && list.length > 0 && (
                list.map((item, index) => (
                        <div key={index}
                             className={activeTab === item.name ? `${styles.tabActive} tabActive` : `${styles.tab} tab`}
                             onClick={() => setActiveTab(item.name)}>
                            <div className={styles.tab__icon}>
                                <img src={item.tabIcon} alt=""/>
                            </div>
                            <p>{item.name}</p>
                        </div>
                    )
                )
            )}
        </div>
    )
}

export default NotificationTabs;