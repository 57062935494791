import styles from './OrderBook.module.scss';
import React, {useState} from "react";
import OrderBookSelect from "./OrderBookSelect";
import OrderBookList from "./OrderBookList/OrderBookList";
import OrderBookLabels from "./OrderBookList/OrderBookLabels";
import BookMiddle from "./OrderBookList/BookMiddle";
import OrderBookComparison from "./OrderBookcomparison";
import {Preloader} from "../../../../../Common/Preloader/Preloader";


const OrderBook = ({text, sell, buy, coinPrice, orderLoading, coin}) => {
    const selectList = [0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001];
    const [selectValue, setSelectValue] = useState(selectList[1])

    return (
        <div className={styles.orderBook}>
            <div className={styles.orderBook__select}>
                <OrderBookSelect selectList={selectList} selectValue={selectValue} setSelectValue={setSelectValue}/>
            </div>
            <OrderBookLabels text={text} coin={coin}/>
            <OrderBookList list={sell} />
            <BookMiddle coinPrice={coinPrice}/>
            <OrderBookList list={buy} />
            {/*{window.innerWidth >= 743 && (*/}
            {/*    <OrderBookComparison />*/}
            {/*)}*/}
            {orderLoading && (
                // <div className={`loadingLocal`} style={{backgroundColor: 'transparent'}}>
                //     <Preloader/>
                // </div>
                <span className={`${styles.formSkeleton} boxLoader`}/>
            )}
        </div>
    )
}

export default OrderBook;