const baseURL = 'https://testexchange.gr-pro.org/api/';

// AUTH

const interceptor = (status) => {
    if (status === 403) {
        setLogout().then(() => window.location.href = "/")
        console.log(' L O G O U T')
    }
}

export const setRegister = async (login, email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/register`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: login,
                email: email,
                password: password,
                token: token,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogout = async () => {
    try {
        const response = await fetch(`${baseURL}auth/logout`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        // const result = 'ok'
        return 'logout';

        // return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogin = async (email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/login`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                token: token,
            }),
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}




// USER




export const getUserInfo = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/info`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log('Unauthorized');
    }
}

export const setUserTheme = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/theme?theme=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setUserLanguage = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/language?language=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserBalances = async (value = null) => {
    try {
        const response = await fetch(value ? `${baseURL}user/profile/balance?coin=${value}` : `${baseURL}user/profile/balance`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getCoinBalance = async (coin) => {
    try {
        const response = await fetch(`${baseURL}user/profile/balance?coin=${coin}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getMarginBalance = async (pair) => {
    try {
        const response = await fetch(`${baseURL}user/profile/balance/margin?pair=${pair}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserTotalBalances = async (margin = false) => {
    try {
        const response = await fetch(`${baseURL}user/profile/total/balance${margin ? '?type=MARGIN' : ''}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


export const getQuestions = async (value) => {
    try {
        const response = await fetch(`${baseURL}questions/get/all?type=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


//        MAIN




export const getTokens = async () => {
    try {
        const response = await fetch(`${baseURL}coins/get/all?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getTokenSettings = async (value) => {
    try {
        const response = await fetch(`${baseURL}coins/get/fund-settings/?asset=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getCoinSettings = async (value, type) => {
    try {
        const response = await fetch(`${baseURL}coins/get/fund-settings/?asset=${value}&type=${type}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getPairPrice = async (value) => {
    try {
        const response = await fetch(`${baseURL}coins/get/pair/price/?pair=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getSpotTokenPairs = async () => {
    try {
        const response = await fetch(`${baseURL}pairs/pair/all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getStakingTokens = async () => {
    try {
        const response = await fetch(`${baseURL}staking/get/all?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getStakingPrice = async (coin) => {
    try {
        const response = await fetch(`${baseURL}coins/get/usd/price?coin=${coin}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setStake = async (id, amount) => {
    try {
        const response = await fetch(`${baseURL}staking/orders/perform/stake`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                planId: id,
                amount: amount,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getStakeOrders = async () => {
    try {
        const response = await fetch(`${baseURL}staking/orders/all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const createAddress = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/deposit/create/address`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: value,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


export const createOrder = async (pair, type, direction, value, price) => {
    try {
        const response = await fetch(`${baseURL}exchange/order/create`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pair: pair,
                type: type,
                direction: direction,
                value: value,
                price: price,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const createMarginOrder = async (pair, type, direction, value, price) => {
    try {
        const response = await fetch(`${baseURL}exchange/margin/create/order`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pair: pair,
                type: type,
                direction: direction,
                value: value,
                price: price,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getClosedOrders = async (type) => {
    try {
        const response = await fetch(`${baseURL}exchange/order/history/list?source=${type}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getOpenOrders = async (type) => {
    try {
        const response = await fetch(`${baseURL}exchange/order/open/list?source=${type}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const removeOpenOrders = async (orderId) => {
    try {
        const response = await fetch(`${baseURL}exchange/order/cancel?orderId=${orderId}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


//    WALLETS



export const getConnectedWallets = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/get/connected-wallets?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserTransactions = async (type, status, coin, startDate, endDate, page = 1, size = 20) => {
    console.log(coin)
    try {
        const response = await fetch(`${baseURL}user/profile/transactions?page=${page}&size=${size}${type === 'ALL' ? '' : '&type=' + type}${status === 'ALL' ? '' : '&status=' + status}${coin === 'ALL' || coin === 'All' ? '' : '&coin=' + coin}${startDate ? '&startDate=' + startDate : ''}${endDate ? '&endDate=' + endDate : ''}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setWallet = async (network, token, name, seed) => {
    try {
        const response = await fetch(`${baseURL}exchange/connect/internal/wallet`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: network,
                coin_name: token,
                wallet_name: name,
                seed_phrase: seed,
                content: 'svg',
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendSwap = async (coinFrom, toCoin, amount) => {
    try {
        const response = await fetch(`${baseURL}exchange/swap/swaps/perform`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fromCoin: coinFrom,
                toCoin: toCoin,
                amt: amount,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendTransfer = async (userId, amount, coin, code) => {
    try {
        const response = await fetch(`${baseURL}exchange/perform/transfer`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-2FA-Secret': code,
            },
            body: JSON.stringify({
                usid: userId,
                amount: amount,
                coin: coin,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendWithdraw = async (coin, network, amount, address, code) => {
    try {
        const response = await fetch(`${baseURL}exchange/withdrawal/withdrawals/create`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-2FA-Secret': code,
            },
            body: JSON.stringify({
                coin: coin,
                network: network,
                amount: amount,
                address: address,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}



        // SETTINGS


export const get2FACode = async () => {
    try {
        const response = await fetch(`${baseURL}auth/2fa/generate/secret`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const generateCode = async (code) => {
    try {
        const response = await fetch(`${baseURL}email/recovery/generate/code`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-2FA-Secret': code,
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendNewEmail = async (code, email) => {
    try {
        const response = await fetch(`${baseURL}email/approve/email/recovery`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                code: code,
                new_email: email,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setEnable2FA = async (code) => {
    try {
        const response = await fetch(`${baseURL}auth/2fa/enable`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-2FA-Secret': code,
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setDisable2FA = async (code) => {
    try {
        const response = await fetch(`${baseURL}auth/2fa/disable`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-2FA-Secret': code,
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getConnectKYC = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/kyc/get/form-url`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setNewName = async (name) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/username`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                username: name,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const postNewPassword = async (oldPass, newPass) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/password`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                old_password: oldPass,
                new_password: newPass,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        return error;
    }
}

export const postMarginTransfer = async (pair, currency, amount, isIn = false) => {
    try {
        const response = await fetch(`${baseURL}exchange/margin/transfer-${isIn ? 'in' : 'out'}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                pair: pair,
                currency: currency,
                amount: amount,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        return error;
    }
}

export const postMarginBorrow = async (pair, currency, amount) => {
    try {
        const response = await fetch(`${baseURL}exchange/margin/request/loan`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                pair: pair,
                currency: currency,
                amount: amount,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        return error;
    }
}

export const postMarginRepay = async (pair, currency, amount) => {
    try {
        const response = await fetch(`${baseURL}exchange/margin/repay/loan`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                pair: pair,
                currency: currency,
                amount: amount,
            }),
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        return error;
    }
}

export const getLoanInfo = async (pair) => {
    try {
        const response = await fetch(`${baseURL}exchange/margin/loan-info?symbol=${pair}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        return error;
    }
}

export const getRecentTradesList = async () => {
    try {
        const response = await fetch(`https://api.huobi.pro/market/history/trade?symbol=ethusdt&size=20`, {
            method: "GET",
        });
        const result = await response.json();
        return await result;
    } catch (error) {
        console.log(error);
    }
}